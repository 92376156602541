/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

const reviewManageRouter = {
  path: '/review-manage',
  component: Layout,
  redirect: '/review-manage/reviewer',
  name: 'ReviewManage',
  meta: {
    title: 'ReviewManage',
    icon: 'component'
  },
  children: [
    {
      path: 'detail',
      component: () => import('@/views/review-manage/detail/index'),
      name: 'ReviewDetail',
      meta: { title: 'ReviewDetail', noCache: true },
      hidden: true
    },
    {
      path: 'reviewer',
      component: () => import('@/views/review-manage/reviewer/list'),
      name: 'Reviewer',
      meta: { title: 'Reviewer', noCache: false },
      hidden: true
    },
    {
      path: 'reviewer/history',
      component: () => import('@/views/review-manage/reviewer/history/list'),
      name: 'ReviewerHistory',
      meta: { title: 'ReviewerHistory', noCache: true },
      hidden: true
    },
    {
      path: 'initial-review',
      component: () => import('@/views/review-manage/initial-review/index'),
      name: 'InitialReview',
      meta: { title: 'InitialReview', noCache: false },
      hidden: true
    },
    {
      path: 'final-review',
      component: () => import('@/views/review-manage/final-review/index'),
      name: 'FinalReview',
      meta: { title: 'FinalReview', noCache: false },
      hidden: true
    },
    {
      //上传剧集统计
      path: 'episode-statistics',
      component: () => import('@/views/review-manage/episodeStatistics/index'),
      name: 'EpisodeStatistics',
      meta: { title: 'episodeStatistics', noCache: false },
      hidden: true
    },
    {
      //上传剧集统计-查看
      path: 'episode-statistics-check/:id',
      component: () => import('@/views/review-manage/episodeStatistics/check/index'),
      name: 'EpisodeStatisticsCheck',
      meta: { title: 'episodeStatisticsCheck', noCache: false },
      hidden: true
    },
    {
      //比例配置
      path: 'ratio-allocation',
      component: () => import('@/views/review-manage/ratioAllocation/index'),
      name: 'RatioAllocation',
      meta: { title: 'ratioAllocation', noCache: false },
      hidden: true
    }
    
  ]
}

export default reviewManageRouter
