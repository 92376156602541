import Vue from 'vue'
import Router from 'vue-router'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}

Vue.use(Router)

/* Layout */
import Layout from '@/layout'

/* Router Modules */
// import componentsRouter from './modules/components'
// import chartsRouter from './modules/charts'
// import tableRouter from './modules/table'
// import nestedRouter from './modules/nested'
import movieManageRouter from './modules/movie-manage'
import crawlerManageRouter from './modules/crawler-manage'
import appManageRouter from './modules/app-manage'
import serviceManage from './modules/service-manage'
import userManageRouter from './modules/user-manage'
import activityManageRouter from './modules/activity-manage'
import developManageRouter from './modules/develop-manage'
import adManageRouter from './modules/ad-manage'
import configurManageRouter from './modules/configur-manage'
import vipManageRouter from './modules/vip-manage'
import userGroupRouter from './modules/user-group'
import dataManageRouter from './modules/data-manage'
import ugcRouter from './modules/ugc-manage'
import communityManageRouter from './modules/community-manage'
import goodsAndOrder from './modules/goods-and-order'
import escortManageRouter from './modules/escort-manage'
import liveManageRouter from './modules/live-manage'
import liveConfigRouter from './modules/live-config'
import cashOutRouter from './modules/cash-out'
import coverLabelManageRouter from './modules/cover-label-manage'
import shortRouter from './modules/short-manage'
import taskRouter from './modules/task-center'
import gameManage from './modules/game-manage'
import reviewManageRouter from './modules/review-manage'


/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/common/redirect/index')
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    meta: { title: 'login' },
    hidden: true
  },
  {
    path: '/report-center',
    component: Layout,
    redirect: '/report-center/index',
    hidden: true,
    children: [
      {
        path: 'index',
        component: () => import('@/views/report-center/index'),
        name: 'ReportCenter',
        meta: { title: 'reportCenter' }
      }
    ]
  },
  {
    path: '/auth-redirect',
    component: () => import('@/views/login/auth-redirect'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/error-page/401'),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        component: () => import('@/views/dashboard/index'),
        name: 'Dashboard',
        //hidden: ,
        meta: {
          title: 'dashboard',
          icon: 'dashboard',
          affix: true,
          noCache: true
        },
        beforeEnter: (to, from, next) => {
          console.log(to, from, '独享守卫')
          next()
        }
      }
    ]
  },
  {
    path: '/profile',
    component: Layout,
    redirect: '/profile/index',
    hidden: true,
    children: [
      {
        path: 'index',
        component: () => import('@/views/common/profile/index'),
        name: 'Profile',
        meta: { title: 'profile', icon: 'user', noCache: true }
      }
    ]
  }
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [
  // 404 page must be placed at the end !!!
  crawlerManageRouter,
  movieManageRouter,
  appManageRouter,
  serviceManage,
  userManageRouter,
  vipManageRouter,
  activityManageRouter,
  developManageRouter,
  adManageRouter,
  configurManageRouter,
  userGroupRouter,
  dataManageRouter,
  ugcRouter,
  communityManageRouter,
  goodsAndOrder,
  escortManageRouter,
  liveManageRouter,
  liveConfigRouter,
  cashOutRouter,
  coverLabelManageRouter,
  shortRouter,
  taskRouter,
  gameManage,
  reviewManageRouter,
  { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  })

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
