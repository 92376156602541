/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

const communityManageRouter = {
  path: '/community-manage',
  component: Layout,
  redirect: '/community-manage/dynamic-manage',
  name: 'CommunityManage',
  meta: {
    title: 'communityManage',
    icon: 'chart'
  },
  children: [
    {
      path: 'dynamic-manage',
      component: () => import('@/views/community-manage/dynamic-manage'),
      name: 'DynamicManage',
      meta: { title: 'dynamicManage', noCache: false }
    },
    {
      path: 'dynamic-manage/manual-add',
      component: () => import('@/views/community-manage/dynamic-manage/manualPosting/form.vue'),
      name: 'ManualAdd',
      meta: { title: 'ManualAdd', noCache: false }
    },
    // 评论管理
    {
      path: 'comment-manage',
      component: () => import('@/views/community-manage/comment-manage'),
      name: 'CommentManage',
      meta: { title: 'commentManage', noCache: false }
    },
    // 话题管理
    {
      path: 'topic-manage',
      component: () => import('@/views/community-manage/topic-manage/index.vue'),
      name: 'TopicManage',
      meta: { title: 'topicManage', noCache: false }
    },
    // 话题管理-新增
    {
      path: 'topic-manage/add',
      component: () => import('@/views/community-manage/topic-manage/pgc/add.vue'),
      name: 'TopicManageAdd',
      meta: { title: 'topicManageAdd', noCache: false }
    },
    // 详情页配置管理
    {
      path: 'detail-tab-config-manage',
      component: () => import('@/views/community-manage/detail-tab-config-manage/index.vue'),
      name: 'DetailTabConfigManage',
      meta: { title: 'detailTabConfigManage', noCache: true }
    },
    // 新增|编辑 讨论tab
    {
      path: 'detail-tab-config-manage/discuss-tab',
      component: () => import('@/views/community-manage/detail-tab-config-manage/discuss/detail.vue'),
      name: 'DiscussDetail',
      meta: { title: 'discussDetail', noCache: false }
    },
    // 新增|编辑 播放器详情页配置
    {
      path: 'detail-tab-config-manage/player',
      component: () => import('@/views/community-manage/detail-tab-config-manage/player/detail.vue'),
      name: 'PlayerDetail',
      meta: { title: 'playerDetail', noCache: false }
    },
    // 新增|编辑 自定义tab配置
    {
      path: 'detail-tab-config-manage/custom',
      component: () => import('@/views/community-manage/detail-tab-config-manage/custom/detail.vue'),
      name: 'CustomDetail',
      meta: { title: 'customDetail', noCache: false }
    },
    {
      //敏感词
      path: 'sensitive',
      component: () => import('@/views/community-manage/sensitive/list.vue'),
      name: 'Sensitive',
      meta: { title: 'Sensitive', noCache: false }
    },
    {
      // 广场banner配置
      path: 'square-banner',
      component: () => import('@/views/community-manage/square-banner'),
      name: 'SquareBanner',
      meta: { title: 'SquareBanner' }
    },
    {
      // 广场方块组配置
      path: 'square-block',
      component: () => import('@/views/community-manage/square-block'),
      name: 'SquareBlock',
      meta: { title: 'SquareBlock' }
    },
    {
      // 热度列表
      path: 'hot-list',
      component: () => import('@/views/community-manage/hot-list'),
      name: 'HotList',
      meta: { title: 'HotList' }
    }
  ]
}

export default communityManageRouter
